@font-face {
    font-family: OpenSans-Quark;
    src: url(font/quark/Quark-Light.otf);
}

@font-face {
    font-family: OpenSans-Quark;
    src: url(font/quark/Quark-Bold.otf);
    font-weight: bold;
}

@font-face {
    font-family: OpenSans-Quark;
    src: url(font/opensans/OpenSans-Light.ttf);
    unicode-range: U+41-5A, U+61-7A, U+C0-FF;
}

@font-face {
    font-family: OpenSans-Quark;
    src: url(font/opensans/OpenSans-Bold.ttf);
    font-weight: bold;
    unicode-range: U+41-5A, U+61-7A, U+C0-FF;
}

html {
    height: 100%;
}

* {
    font-family: OpenSans-Quark, Helvetica, sans-serif;
}

body {
    height: 100%;
    /* background-color: aqua; */
}

.nav-link {
    margin-right: 1rem;
    margin-left: 1rem;
}

.navActive {
    font-weight: bold;
    /* border-bottom: solid 3px #135193; */
}

.mobile-nav-container {
    background-color: #135193;
    width: 100%;
    height: 100%;
}

.mobile-nav {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 50%;
    margin: 0;
    /* margin: -15% 0 0 -25%; */
}

.mobile-nav .nav-link {
    color: #FFFFFF75;
}

.mobile-nav .navActive {
    color: #FFFFFF;
    border-bottom: 0px;
}

.open-mobile-menu {
    text-decoration: none;
    color: #135193;
    padding: 2rem;
}

.dismiss-mobile-menu {
    text-decoration: none;
    color: white;
    padding: 2rem;
}

#root {
    height: 100%;
}

.article-box {
    background-color: #FFFFFF90;
    border: #135193 5px solid;
}

.article-box:hover {
    background-color: #13519330;
    transition: 0.5s;
}

.article-read-more {
    border-width: 1rem;
    margin: 0.5rem;
    text-align: right;
}

.article-read-more a {
    text-decoration: none;
    font-weight: bold;
    color: #00000075;
}

.article-read-more a:hover {
    text-decoration: none;
    font-weight: bold;
    color: black;
    border-bottom: #135193 2px solid;
}

/* #mynav { */
    /* background-color: #135193;
    font-size: large; */
    /* min-width: 250px;
    max-width: 250px; */
    /* min-height: 100vh;
    width: 100%;
    transition: all 0.3s; */
    /* margin-left: -250px; */
    /*position: fixed;*/
    /*top: 0;*/
    /*left: 0;*/
    /*height: 100vh;*/
    /*z-index: 999;*/
    /*width: 25%;*/
    /*height: 100%;*/
/* } */

/* #mynav.active {
    margin-left: 0px;
}

#myContainer {
    min-height: 100%;
}

#myContent {
    padding: 0rem 1.5rem 1.5rem 1.5rem;
    width: 100%;
    font-size: 1.25rem;
}

#sidebarCollapse {
    color: #135193;
} */

.wrapper {
    display: flex;
    align-items: stretch;
}

.hr {
    border-color: #135193;
    border-width: thick;
}

.footer {
    background-color: #135193;
    padding: 1em;
    /* padding-top: 2rem; */
    color: white;
    text-align: center;
    font-size: 0.75em;
}

.imgprofile {
    border-radius: 50%;
    height: 200px;
}

.social-img {
    height: 2.5rem;
}

/* #desktopmenu {
    display: block;
}

#mobilemenu {
    display: none;
} */

/*.center-box {*/

/*    display: table-cell;*/

/*    vertical-align: middle;*/

/*    height: 3.5rem;*/

/*    width: 100%;*/

/*}*/

.header-box {
    background-color: #135193;
    border-radius: 0.35rem;
    color: white;
    padding: 0.5rem;
    margin: 2.5rem auto 1rem auto;
}

.header-box h2, h3 {
    margin: auto;
}

.social-box {
    background-color: #0984e3;
    /*padding: 1rem;*/
    border-radius: 0.35rem;
}

.my-social-link {
    font-weight: bold;
    text-decoration: none;
    color: black;
}

/* .my-social-link:hover {
    font-weight: bold;
    text-decoration: none;
    color: black;
    text-shadow: 2px 2px 4px #ccc;
    transition: 0.2s;
} */

.social-svg {
    fill: black;
}

p {
    font-size: 1.25rem;
}

.head-text {
    border-bottom: 5px #135193 solid;
}

.pro-skill-box {
    /*background-color: #2ed573;*/
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.pro-skill-box ul > li {
    list-style-type: none;
    padding-bottom: 0.5rem;
}

/* .pro-skill-box ul:hover {
    border: 10px black solid;
    border-radius: 0.5rem;
    box-shadow: 3px 3px 5px 6px #ccc;
    transition: 0.2s;
} */

.pro-skill-icon {
    width: 2rem;
    margin-right: 1rem;
    margin-bottom: 0.75rem;
}

li {
    font-size: 1.2rem;
}

.interested-box {
    padding: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
}

/*.interested-box:hover{*/

/*    border-radius: 0.35rem;*/

/*    box-shadow: 3px 3px 5px 6px #ccc;*/

/*    transition: 0.2s;*/

/*}*/

.my-fields {
    background-color: #0984e3;
    border-radius: 0.35rem;
}

.page-name {
    margin: 1.5rem auto 1.5rem auto;
    width: 100%;
}

.my-circle-point {
    border-radius: 50%;
    background-color: #135193;
    height: 1rem;
    width: 1rem;
    display: inline-block;
}

.unigo-icon {
    width: 10rem;
    border-radius: 1rem;
}

.unigo-poster {
    width: 50%;
}

.fmh-logo {
    width: 10rem;
}

.box-center {
    display: flex;
    /*justify-content: center;*/
    /*align-items: center;*/
    height: 100%;
    width: 100%;
}

/* @media screen and (max-width: 600px) {
    #desktopmenu {
        display: none;
    }
    #mobilemenu {
        display: block;
    }
    #mynav {
        margin-left: -250px;
    }
    #mynav.active {
        margin-left: 0;
    }
    #myContent {
        margin-top: 0.5rem;
    }
    .unigo-poster {
        width: 100%;
        margin-top: 2rem;
    }
} */